(function () {
    'use strict';
    /* App Module */
    angular.module('buddyApp', [

        'ui.router',        
        
        'app.auth',
        'app.dash',
        'app.landing',
        'app.profile',
        'app.fin',
        'app.common',

    ])
    .config(conf);

    conf.$inject = ['$locationProvider'];    
    function conf($locationProvider) {
    	$locationProvider.html5Mode(true);
    }
}());