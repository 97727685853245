(function (angular) {
    'use strict';
    angular
        .module('app.landing', ['ui.router', 'slick', 'vcRecaptcha'])
        .config(routes);
    routes.$inject = ['$stateProvider'];
    
    function routes($stateProvider) {
        
        $stateProvider
            .state('landing', {
                url: '/',
                templateUrl: '/views/Landing/landing.html',
                controller: 'LandingCtrl',
                controllerAs: 'landCtrl'
            })
            .state('contacts', {
                url: '/contacts',
                templateUrl: '/views/Landing/contact-us.html',
               controller: 'LandingCtrl',
                controllerAs: 'landCtrl'
            })                       
    }
})(angular);
