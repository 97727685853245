(function (angular) {
    'use strict';
    angular
        .module('app.profile')
        .controller('ProfileCtrl', ProfileCtrl);
    ProfileCtrl.$inject = ['$q', '$rootScope', '$scope', 'ProfileService', 'AuthService', 'ngDialog', '$state', '$timeout'];

    function ProfileCtrl($q, $rootScope, $scope, ProfileService, AuthService, ngDialog, $state, $timeout) {
    	
    	var vm = this;
        vm.mailRegExp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;    	

    	vm.loadProfile = function(){
    		ProfileService.getProfile().then(
                function(res){
                    $rootScope.parent = res;                    
                }, function(err){
                    console.log(err);                   
                    $state.go('landing');
                });      		
    	};       
    	
    	if (!$rootScope.parent) {
    		vm.loadProfile();
    	}

    	vm.saveEditedProfile = function(firstname, lastname, email, editEmail, newEmail, oldPass, pass, confPass, weeklyReport, promo){
    		if (pass != confPass) {
    			return;
    		}
    		var data = {
    			oldPass: oldPass
    		};
            
    		if (firstname !== undefined) {
    			data.firstname = firstname;
    		}
    		if (lastname !== undefined) {
    			data.lastname = lastname;
    		}

            if (editEmail !== undefined) {
                data.email = newEmail
            } else if (email !== undefined) {
                data.email = email;
            } 
    		
    		if (pass !== undefined && pass !== '') {
    			data.pass = pass;
    		}
    		if (weeklyReport !== undefined) {
    			data.weeklyReport = weeklyReport;
    		}
    		if (promo !== undefined) {
    			data.promo = promo;
    		}
            
    		ProfileService.editProfile(data).then(
                function(res){
                    vm.profileEdited = true; 
                    alert('Profile edited');                   
                }, function(err){
                    console.log(err);                    
                    // vm.emailRegistred = true;                   
                    // vm.oldPassIncorrect = true;                   
                }); 
      	// console.log(data);
    		
    	};

    	vm.editChild = function(avatar, firstname, lastname, username, pass, id){
            
            if (avatar === undefined) {
            	avatar = '/images/player1.png';
            }

            var data = {
            	id: id
            };
    		if (firstname !== undefined) {
    			data.firstname = firstname;
    		}
    		if (lastname !== undefined) {
    			data.lastname = lastname;
    		}
    		if (username !== undefined) {
    			data.username = username;
    		}
    		if (pass !== undefined && pass !== '') {
    			data.pass = pass;
    		}
    		if (avatar !== undefined) {
    			data.avatar = avatar;
    		}    		
    		if(!data){
    			return;
    		}     		
    		ProfileService.editChild(data).then(
    			function(res){
                    vm.childEditedSuc = true;
                    $timeout(function (argument) {
                        vm.childEditedSuc = false;
                    }, 3000);	
    			}, function(err){
                    console.log(err);
    				vm.loginRegistred = true;
    			});    			
    	};

    	vm.loadUserPurchases = function(){
            ProfileService.getPurchases().then(
                function(res){
                    vm.purchasedTests = res;
                }, function(err){
                    console.log(err);                    
                });
        };
    	vm.loadAvaliablePurchases = function(){
    		ProfileService.getAvaliablePurchases().then(
                function(res){
                    vm.avaliableTests=res;                    
                }, function(err){
                    console.log(err);                    
                });
    	};
    	var testDialog;
    	vm.openTestPreview = function(test){
    		testDialog = ngDialog.open({ 
				template: '/views/Profile/popup-test-preview.html', 
				className: 'popup-test-preview ngdialog-theme-default',
				data: {
					test: test
				},
                scope: $scope,	
    		});
		};

		vm.closePopup = function(){
			ngDialog.closeAll();
		}

    	vm.buyItems = function(id){
            var data = {child_id: id};
            var ids = [];
            var items = angular.element(document.getElementsByClassName("aval-test-item"));         
            for (var i = 0; i < items.length; i++) {            
                if (items[i].checked) {
                    ids.push(parseInt(items[i].value, 10));
                }
            }
            if (ids.length > 0) {
                data.tests = ids;
            } else {
                return;
            }           
            
            ProfileService.buyExtraLvls(data).then(
                function(res){
                    $state.go('customize');                   
                }, function(err){
                    console.log(err);
                    vm.loginRegistred = true;
                }); 
        };

        vm.checkEmptyFields = function(repeated){
            if (repeated[repeated.length-1].username && repeated[repeated.length-1].email) {
                vm.showMoreButton = true;
            } else {
                vm.showMoreButton = false;
            }               
        };
        vm.addInviteFields = function(repeated){            
            if (repeated[repeated.length-1].username && repeated[repeated.length-1].email) {
                repeated.push({});
                vm.showMoreButton = false;
            }            
        };

        vm.inviteFriend = function(friends){
            var filteredFriends=[];            
            for (var i = 0; i < friends.length; i++) {
                if (friends[i].username && friends[i].email) {
                    filteredFriends.push({username: friends[i].username, email: friends[i].email});
                }
            }        	
        	var data = {
        		friends: filteredFriends
        	};            
            ProfileService.sendInvite(data).then(
				function(res){
					vm.invitedFriends = [{}];                    
                    alert('Invite has been sent');			      
				}, function(err){
					console.log(err);
					 // vm.invalidPass = true;
					 // vm.emailRegistred = true;
				});
        };
    

    }
})
(angular);