(function (angular) {
    'use strict';
    angular
        .module('app.auth')
        .factory('AuthService', AuthService);
    AuthService.$inject = ['$q', '$http'];

    function AuthService($q, $http) {

        return {
            regNewUser: regNewUser,
            restorePassword: restorePassword,
            regNewChild: regNewChild,
            parentSignIn: parentSignIn,
            parentSignUp: parentSignUp,
            childLogIn: childLogIn,
            logOut:logOut,
            loginAsChild:loginAsChild,
            loginAsParent:loginAsParent,
            saveNewPassword:saveNewPassword
        }

        function regNewUser(data){          
            var defer = $q.defer();
            $http.post('/api/registration', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
            
        }
        function restorePassword(data){          
            var defer = $q.defer();
            $http.post('/api/forget/password ', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
            
        }
        function regNewChild(data){            
            var defer = $q.defer();
            $http.post('/api/registration/child', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function parentSignIn(data){                
            var defer = $q.defer();
            $http.post('/api/login/parent', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
        }
        function parentSignUp(data){                
            var defer = $q.defer();
            $http.post('/api/sign', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
        }
        function childLogIn(data){                
            var defer = $q.defer();
            $http.post('/api/login/child', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function logOut(){                
            var defer = $q.defer();
            $http.get('/api/logout')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
        }
        function signOnNews(data){                
            var defer = $q.defer();
            $http.post('/api/sign_on_news', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
        }
        function loginAsChild(childId){                
            var defer = $q.defer();
            $http.get('/api/login/as/child/'+childId)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
        }
        function loginAsParent(){                
            var defer = $q.defer();
            $http.get('/api/login/as/parent')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
        }
        function saveNewPassword(data){                
            var defer = $q.defer();
            $http.post('/api/forget', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;
        }

        

    }
})
(angular);