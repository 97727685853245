(function (angular) {
    'use strict';
    angular
        .module('app.auth')
        .controller('RegCtrl', RegCtrl);
    RegCtrl.$inject = ['$q', '$rootScope', '$scope', 'AuthService', '$state', 'ProfileService', '$timeout', 'ngDialog'];

    function RegCtrl($q, $rootScope, $scope, AuthService, $state, ProfileService, $timeout, ngDialog) {

    	var vm = this;
        vm.loadProfile = function(){
            ProfileService.getProfile().then(
                function(res){                    
                    $rootScope.parent = res;
                    if (res.children) {
                        $rootScope.child = res.children[0];
                    }
                }, function(err){
                    console.log(err);                    
                });             
        };
        if (!$rootScope.parent) {
            vm.loadProfile();
        }
    	vm.mailRegExp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;
    	vm.submitReg = function(firstname, lastname, email, pass, passConf, weeklyReport, promo){ 
            if (pass !== passConf) {
                return;
            }            
            var upper = false;
            var lower = false;
            for(var i=0; i<pass.length; i++){
                var passChar = pass.charCodeAt(i);                
                if (passChar >= 65 && passChar <= 90) {
                    upper = true;
                } else if(passChar >= 97 && passChar <= 122){
                    lower = true;
                }
            }
            if (!upper || !lower) {
                vm.invPassFormat = true;
                return;
            }
            var data = {
                firstname: firstname,
                lastname: lastname,
                email: email,
                password: pass
            };
            if (weeklyReport !== undefined) {
                data.weeklyReport = weeklyReport;
            } else {
                data.weeklyReport = false;
            }
            if (promo !== undefined) {
                data.promo = promo;
            } else {
                data.promo = false;
            }

            AuthService.regNewUser(data).then(
                function(res){                   
                ngDialog.open({ 
                        template: '/views/Common/popup-activate-account.html', 
                        className: 'ngdialog-theme-default',
                        data: {
                            email: email
                        },
                        scope: $scope      
                    });
                }, function(err){
                    console.log(err);
                    if (err.email) {
                        vm.emailRegistred = true;
                    }                    
                });             
        };
        vm.regNewChild = function(avatar, name, grade, login, pass, passConf){
            if (pass !== passConf) {
                return;
            }
            var upper = false;
            var lower = false;
            for(var i=0; i<pass.length; i++){
                var passChar = pass.charCodeAt(i);                
                if (passChar >= 65 && passChar <= 90) {
                    upper = true;
                } else if(passChar >= 97 && passChar <= 122){
                    lower = true;
                }
            }
            if (!upper || !lower) {
                vm.invPassFormat = true;
                return;
            } 
            if (avatar === undefined) {
                avatar = '/images/player1.png';
            }
    		var data = {
    			avatar: avatar,
                name: name,
    			grade: grade,
    			login: login,
    			password: pass
    		};
            
    		AuthService.regNewChild(data).then(
    			function(res){		
    				$state.go('dash-parent');
    			}, function(err){
                    if (err.login) {
                        vm.loginRegistred = true;
                    }
    				
    			});    			
    	}; 
        vm.logOut = function(){
            AuthService.logOut().then(
             function(res){
                 $state.go('landing');
             }, function(err){
                console.log(err);
             });
        };

        var isWebkit = 'WebkitAppearance' in document.documentElement.style;        
        vm.goTo = function(pathName){            
            $rootScope.pathToScroll = pathName;
            $state.go('landing');
                 
        };

        
    }
})
(angular);