(function (angular) {
    'use strict';
    angular
        .module('app.dash', ['ui.router', 'angular-svg-round-progressbar', 'ngDraggable'])
        .config(routes);
    routes.$inject = ['$stateProvider'];
    
    function routes($stateProvider) {
        
        $stateProvider
            .state('dash-parent', {
                url: '/dashboard',
                templateUrl: '/views/Dashboard/dash.html',
                controller: 'DashCtrl',
                controllerAs: 'dashCtrl'
            })
            .state('dash-child', {
                url: '/dash',
                templateUrl: '/views/Dashboard/dash-child.html',
                controller: 'DashChildCtrl',
                controllerAs: 'dashChildCtrl'
            })
            .state('dash-child-reports', {
                url: '/dash/reports',
                templateUrl: '/views/Dashboard/reports.html',
                controller: 'DashChildCtrl',
                controllerAs: 'dashChildCtrl'
            })
            .state('dash-child-rewards', {
                url: '/dash/rewards',
                templateUrl: '/views/Dashboard/rewards.html',
                controller: 'DashChildCtrl',
                controllerAs: 'dashChildCtrl'
            })
            .state('dash-child-parent-rewards', {
                url: '/dash/surprises',
                templateUrl: '/views/Dashboard/surprises.html',
                controller: 'DashChildCtrl',
                controllerAs: 'dashChildCtrl'
            });                       
    }
})(angular);
