(function (angular) {
    'use strict';
    angular
        .module('app.profile', ['ui.router', 'ngDialog'])
        .config(routes);
    routes.$inject = ['$stateProvider'];
    
    function routes($stateProvider) {
        
        $stateProvider
            .state('profile', {
                url: '/profile',
                templateUrl: '/views/Profile/profile.html',
                controller: 'ProfileCtrl',
                controllerAs: 'profileCtrl'
            })
            .state('invite', {
                url: '/invite',
                templateUrl: '/views/Profile/invite.html',
                controller: 'ProfileCtrl',
                controllerAs: 'profileCtrl'
            })
            .state('deals', {
                url: '/deals',
                templateUrl: '/views/Profile/deals.html',
                controller: 'ProfileCtrl',
                controllerAs: 'profileCtrl'
            })
            .state('customize', {
                url: '/customize',
                templateUrl: '/views/Profile/customize.html',
                controller: 'ProfileCtrl',
                controllerAs: 'profileCtrl'
            })                       
    }
})(angular);
