(function (angular) {
    'use strict';
    angular
        .module('app.auth')
        .controller('AuthCtrl', AuthCtrl);
    AuthCtrl.$inject = ['$q', '$rootScope', '$scope', 'ngDialog', '$state', 'AuthService', '$timeout'];

    function AuthCtrl($q, $rootScope, $scope, ngDialog, $state, AuthService, $timeout) {
    	var vm = this;
        vm.mailRegExp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;
    	var parentSignPopup;
    	var childSignPopup;

        /*Links for FB and G+ auth buttons*/
        var origin = window.location.origin;
        vm.FBLink = origin+'/api/auth/facebook/redirect';        
        vm.GPLink = origin+'/api/auth/google/redirect';

        var parentModal;
        var childModal;       

        vm.closePopup = function(){
            ngDialog.closeAll();
        };
        
        vm.signUpParent = function(mail, pass){
            var data = {
                mail: mail,
                password: pass
            };
            AuthService.parentSignUp(data).then(
             function(res){               
                 ngDialog.closeAll();    
                 $state.go('auth');
             }, function(err){                 
                 vm.emailRegistred = true;
             });
        };

        vm.signInParent = function(mail, pass){
            var data = {
                mail: mail,
                password: pass
            };
            AuthService.parentSignIn(data).then(
             function(res){              
                 ngDialog.closeAll();
                 $rootScope.child = '';   
                 $rootScope.parent = res;
                 $state.go('dash-parent');
             }, function(err){
                 console.log(err);
                 vm.invalidParentData = true;
             });
        };
        function addOpacity(blockName){
            $timeout(function(){                
                var popup = angular.element(document.getElementsByClassName(blockName)).removeClass("invisible");                
            }, 500);
        }

        vm.signInStudent = function(login, pass){
            var data = {
                login: login,
                password: pass
            };   
            AuthService.childLogIn(data).then(
            function(res){              
                ngDialog.closeAll();                
                $rootScope.parent = '';                
                $rootScope.child = res;
                $rootScope.currGrade = res.SavedGrades[0];
                $rootScope.currGradeIndex = 0;
                $rootScope.currTest = $rootScope.currGrade.SavedTests[0];
                $rootScope.currTestIndex = 0;
                $rootScope.childLoaded = true;
                $state.go('dash-child');
            }, function(err){                
                vm.invalidChildData = true;
            });
        };

    }
})
(angular);