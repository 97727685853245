(function (angular) {
    'use strict';
    angular
        .module('buddyApp')
        .controller('MainCtrl', MainCtrl);
    MainCtrl.$inject = ['$q', '$rootScope', '$scope', 'AuthService', 'LandingService', 'ngDialog', '$state', 'ProfileService',
        'PaymentService', '$timeout'];

    function MainCtrl($q, $rootScope, $scope, AuthService, LandingService, ngDialog, $state, ProfileService,
        PaymentService, $timeout) {

        var vm = this;

        if (vm.duration === undefined) {
            vm.duration = 0;
        }  

    	vm.logOut = function(){
            AuthService.logOut().then(
             function(res){                 
                 $rootScope.parent = '';
                 $rootScope.child = '';
                 $state.go('landing');
                 $rootScope.fromParent = '';
             }, function(err){
                console.log(err);
             });
        }
        vm.goRoute = function(route, dur){
            if (dur) {
                vm.duration = dur;
            }              
            $state.go(route);             
        };
        var isWebkit = 'WebkitAppearance' in document.documentElement.style; 
        vm.scrollTopGk = function(){      
            var scrollingElement = isWebkit ? document.body : document.documentElement;               
            angular.element(scrollingElement)[0].scrollTop = 0;
        }

        vm.checkCoupon = function(coupon){            
            var data = {
                coupon: coupon
            };
            ProfileService.checkCoupon(data)
                .then(
                    function(res){
                        vm.invalidCoupon = false;
                        vm.couponCode = coupon;
                        vm.couponDiscount = res;

                    }, function(err){
                        vm.invalidCoupon = true;
                        console.log(err);                    
                    });
        };

        vm.getTime = function(time){
            if (time === undefined || time === 0 || time === "") {
                return 'No time yet';
            }
            // var days = Math.floor(time/86400000);
            // days > 0 ? time = time - days*86400000 : '';
            var hours = Math.floor(time/3600000);
            hours > 0 ? time = time - hours*3600000 : '';
            var min = Math.floor(time/60000);
            min > 0 ? time = time - min*60000 : '';         
            // var sec = Math.floor(time/1000);
            var formattedTime = "";
            // days > 0 ? formattedTime += days + ' d ' : '';
            hours > 0 ? formattedTime += hours + ' h ' : '';
            min > 0 ? formattedTime += min + ' min ' : '';
            // sec > 0 ? formattedTime += sec + ' sec' : '';
            return formattedTime;
        };

        vm.loginAsChild = function(childId){ 
            // console.log(childId);           
            AuthService.loginAsChild(childId).then(
                function(res){                
                    $rootScope.child = res;                             
                    $rootScope.fromParent = 'true';
                    $rootScope.parent = '';
                    $rootScope.currTest = '';
                    $rootScope.currGrade = '';
                    $rootScope.currGrade = res.SavedGrades[0];
                    $rootScope.currGradeIndex = 0;
                    $rootScope.currTest = $rootScope.currGrade.SavedTests[0];
                    $rootScope.currTestIndex = 0;
                    $rootScope.childLoaded = true; 
                    $state.go('dash-child');
                }, function(err){
                    console.log(err);
                    
                });             
        };
        vm.loginAsParent = function(){                  
            AuthService.loginAsParent().then(
                function(res){                              
                    $rootScope.fromParent = 'false';
                    $rootScope.child = '';
                    $rootScope.currTest = '';
                    $rootScope.parent = res;
                    $rootScope.parentLoaded = true;                                       
                    $state.go('dash-parent');
                }, function(err){
                    console.log(err);
                    
                });             
        };
        vm.redirectTo = function(){             
            setTimeout(function(){
                window.location.reload();
            }, 0);          
        };

        vm.buyForSingle = function (duration) {
            var data = {};
            if (duration === 'yr') {
                data.type = 12;
            } else if(duration === 'qtr'){
                data.type = 3;
            } else {
                return;
            }
            PaymentService.buyForSingle(data).then(
                function(res){
                    $state.go('finances');                        
                }, function(err){
                    console.log(err);
                    
                });
        };

        vm.getCartData = function(){ 
            PaymentService.getCartData().then(
                function(res){
                    var testsCntr = 0;                    
                    vm.testsAmnt = testsCntr;
                    vm.accAmnt = res.length;
                    vm.cartData = res;
                    vm.cartDataLoaded = true;
                }, function(err){
                    console.log(err);                    
                });             
        };

        vm.buyItems = function(period){
            var data = {};
            var children = [];
            var childrenBlocks = angular.element(document.getElementsByClassName("child-tests"));           
            for (var i = 0; i < childrenBlocks.length; i++) {                
                var childId = angular.element(childrenBlocks[i].getElementsByClassName("child-id"))[0].value;
                var unit = {};
                var ids = [];
                var items = angular.element(childrenBlocks[i].getElementsByClassName("test-to-buy")); 
                for (var j = 0; j < items.length; j++) {            
                    if (items[j].checked) {
                        ids.push(parseInt(items[j].value, 10));
                    }
                }               
                if (ids.length > 0) {
                    unit.child_id = childId;
                    unit.topics = ids;
                    children.push(unit);
                }
            }
            data.type = period;
            vm.couponCode ? data.coupon = vm.couponCode : data.coupon = '';
            data.children = children;            
            ProfileService.buyItems(data)
                .then(
                    function(res){                       
                        $state.go('finances');                        
                    }, function(err){
                        console.log(err);                    
                    });
        };
        vm.buyExtraItems = function(period){
            var data = {};
            var children = [];           
            var childrenBlocks = angular.element(document.getElementsByClassName("child-tests"));           
            for (var i = 0; i < childrenBlocks.length; i++) {                             
                var childId = angular.element(childrenBlocks[i].getElementsByClassName("child-id"))[0].value;
                var unit = {};
                var ids = [];
                var items = angular.element(childrenBlocks[i].getElementsByClassName("test-to-buy")); 
                for (var j = 0; j < items.length; j++) {            
                    if (items[j].checked) {
                        ids.push(parseInt(items[j].value, 10));
                    }
                }               
                if (ids.length > 0) {                   
                    data.topics = ids;
                } else {
                    return;
                }
            }            
            data.child_id = childId;
            vm.couponCode ? data.coupon = vm.couponCode : data.coupon = '';            
            data.extra = 1;                              
            ProfileService.buyExtraItems(data)
                .then(
                    function(res){                       
                        $state.go('finances');                        
                    }, function(err){
                        console.log(err);                    
                    });
        };

        vm.changeCounters = function (flag, term) {
            if (flag !== 'extra') {
                $timeout(function(){
                    var children = []; 
                    var discountRate = 0;              
                    var childrenBlocks = angular.element(document.getElementsByClassName("child-tests"));
                    var childrenCntr = 0;                            
                    for (var i = 0; i < childrenBlocks.length; i++) {  
                        childrenCntr++;                             
                        var childId = angular.element(childrenBlocks[i].getElementsByClassName("child-id"))[0].value;
                        var unit = {};
                        var ids = [];                    
                        var items = angular.element(childrenBlocks[i].getElementsByClassName("test-to-buy")); 
                        for (var j = 0; j < items.length; j++) {            
                            if (items[j].checked) {
                                ids.push(parseInt(items[j].value, 10));
                            }
                        }                                   
                        if (ids.length > 0) {
                            unit.child_id = childId;
                            unit.topics = ids;
                            children.push(unit);
                        }
                    }                    
                    var testsCntr = 0;
                    for (var i = 0; i < children.length; i++) {
                        testsCntr += children[i].topics.length;
                    }

                    switch(testsCntr){
                        case 0:
                            discountRate = 0;
                            break;
                        case 1:
                            if (childrenCntr === 1) {
                                if (term === 3) {
                                    discountRate = 15;
                                } else if(term === 12){
                                    discountRate = 25;
                                } else {
                                    discountRate = 0;
                                }
                            } else {
                                discountRate = 0;
                            }                          
                            break;
                        case 2:
                          discountRate = 5;
                          break;
                        case 3:
                          discountRate = 10;
                          break;
                        case 4:
                          discountRate = 15;
                          break;
                        case 5:
                          discountRate = 20;
                          break;
                        case 6:
                          discountRate = 25;
                          break;
                        default:
                            discountRate = 25;
                            break; 
                    }

                    switch(children.length){
                        case 0:
                        case 1:                        
                            discountRate > 0 ? '' : discountRate = 0;                      
                          break;
                        case 2:
                          discountRate < 7 ? discountRate = 7 : '';                      
                          break;
                        case 3:
                          discountRate < 12 ? discountRate = 12 : '';
                          break;
                        case 4:
                          discountRate < 17 ? discountRate = 17 : '';
                          break;
                        case 5:
                          discountRate < 23 ? discountRate = 23 : '';
                          break;
                        case 6:
                          discountRate < 25 ? discountRate = 25 : '';
                          break;
                        default:
                            discountRate < 25 ? discountRate = 25 : '';
                            break; 
                    }

                    vm.discountRate = discountRate;
                    //Price 
                    var ttlPrice = 0;
                        for (var i = 0; i < vm.cartData.length; i++) {
                            vm.cartData[i].grades.forEach(function(grade){
                                grade.available_tests.forEach(function(test){
                                    if (test.buyItemCB) {
                                         ttlPrice+= test.price;
                                     }
                                })
                            });
                        }
                    vm.testsAmnt = testsCntr;
                    vm.accAmnt = children.length;
                    vm.sumPrice = ttlPrice;
                },0); 
            } else {
                var ttlPrice = 0;
                for (var i = 0; i < vm.cartData.available_tests.length; i++) {                    
                    if (vm.cartData.available_tests[i].buyItemCB) {
                        ttlPrice+= vm.cartData.available_tests[i].price;
                    }
                       
                }
                vm.sumPrice = ttlPrice;
            }           
        };

        vm.cancelationPopup = function() {
            ngDialog.open({ 
                template: '/views/Common/popup-cancel-subs.html', 
                className: 'popup-cancelation ngdialog-theme-default',                
                scope: $scope      
            });
        };

        var chooseModal = null;
        function addOpacity(blockName){
            $timeout(function(){                
                var popup = angular.element(document.getElementsByClassName(blockName)).removeClass("invisible");                
            }, 500);
        }
        vm.openChoosePopup = function(){
            // console.log('Choose');            
            chooseModal = ngDialog.open({ 
                template: '/views/Auth/popup-choose-sign-in.html', 
                className: 'popup-choose-sign-in invisible ngdialog-theme-default',               
                scope: $scope,               
                controller: 'AuthCtrl',
                controllerAs: 'authCtrl',
                name: 'chooseModal'                       
            });
            addOpacity('popup-choose-sign-in');
        };

        var parentModal;
        vm.openParentPopup = function(flag){
            if (chooseModal) {
                chooseModal.close();
            }            
            parentModal = ngDialog.open({ 
                template: '/views/Auth/popup-parent-sign.html', 
                className: 'popup-parent-sign invisible ngdialog-theme-default',
                data: {
                    flag: flag
                },
                scope: $scope,
                controller: 'AuthCtrl',
                controllerAs: 'authCtrl'                 
            });
            addOpacity('popup-parent-sign');
        };
                     
        vm.openChildPopup = function(){
            chooseModal.close();
            ngDialog.open({ 
                template: '/views/Auth/popup-child-sign.html', 
                className: 'popup-child-sign invisible ngdialog-theme-default',
                scope: $scope,
                controller: 'AuthCtrl',
                controllerAs: 'authCtrl'                     
            });
            addOpacity('popup-child-sign');
        };

        vm.closePopup = function(){            
            ngDialog.closeAll();
            if (vm.socRegPopup) {
                vm.socRegPopup = false;
            }
        };

        vm.unsubscribe = function () {
            vm.unsubSuccess = true;
            PaymentService.unsubscribe()
                .then(
                    function(res){                  
                        $rootScope.parent.subscriptions = res.subscriptions;
                        $rootScope.parent.active_subs = 0;
                        vm.unsubSuccess = true; 
                    }, function(err){
                        console.log(err);                    
                    });
        };

        vm.markAll = function(flag, extra){
            if (extra !== "extra") {
                for (var i = 0; i < vm.cartData.length; i++) {
                    vm.cartData[i].grades.forEach(function(grade){
                        grade.available_tests.forEach(function(test){
                            test.buyItemCB = flag;
                        })
                    });
                }
                vm.changeCounters();
            } else {
                for (var i = 0; i < vm.cartData.available_tests.length; i++) {
                    vm.cartData.available_tests[i].buyItemCB = flag;                       
                }
                vm.changeCounters('extra');
            }
            
        };

        var isWebkit = 'WebkitAppearance' in document.documentElement.style;        
        vm.goTo = function(pathName){            
            if ($state.current.name !== 'landing') {
                $state.go('landing');
            }

           $timeout(function(){
                if(pathName === 'faq'){

                } else {
                    var lastElemScrollAmount = angular.element( document.querySelector( '#'+pathName ) )[0].offsetTop;
                    var scrollingElement = isWebkit ? document.body : document.documentElement;               
                    angular.element(scrollingElement)[0].scrollTop = lastElemScrollAmount-38;
                }
            },0);        
        };

        vm.contactForm = function(mail, subj, comment) {            
            var data = {
                email: mail,
                subj: subj,
                comment: comment
            };
            LandingService.contactForm(data)
                .then(
                    function(res){                       
                        vm.email = '';
                        vm.subj = '';
                        vm.comm = '';
                        alert('Your message have been sent');                        
                    }, function(err){
                        console.log(err);                    
                    });
        };

        vm.round = Math.round;   

    }
})
(angular);