(function (angular) {
    'use strict';
    angular
        .module('app.fin')
        .controller('FinCtrl', FinCtrl);
    FinCtrl.$inject = ['$q', '$rootScope', '$scope', 'PaymentService', '$state'];

    function FinCtrl($q, $rootScope, $scope, PaymentService, $state) {

    	var vm = this;

    	var form = document.querySelector('#checkout-form');
		var submit = document.querySelector('#submit-btn');

		
		function getPrice() {
			PaymentService.getPrice()
				.then(function (res) {										
					vm.calculatedPrice = Math.round(res*100)/100;
				},
				function (err) {
					console.log(err);
				})
		};
		getPrice();

    	vm.getToken = function(flag){
    		PaymentService.getToken().then(
    			function(res){
    				vm.clientToken = res;    					
		    				braintree.client.create({
							  authorization: res
							}, function (clientErr, clientInstance) {						
								if (clientErr) {
								    console.log(clientErr);
								    return;
								}								
									braintree.hostedFields.create({
								    client: clientInstance,
								    styles: {
								      'input': {
								        'font-size': '14pt'
								      },
								      'input.invalid': {
								        'color': 'red'
								      },
								      'input.valid': {
								        'color': 'green'
								      }
								    },
								    fields: {
								      number: {
								        selector: '#card-number',
								        placeholder: '4111 1111 1111 1111'
								      },
								      cvv: {
								        selector: '#cvv',
								        placeholder: '123'
								      },
								      expirationDate: {
								        selector: '#expiration-date',
								        placeholder: '10/2019'
								      }
								    }
								  }, function (hostedFieldsErr, hostedFieldsInstance) {
								    if (hostedFieldsErr) {
								      // Handle error in Hosted Fields creation
								      return;
								    }

								    $scope.$apply(function() {
								        vm.fieldsLoaded = true;								       
								    });

								    form.addEventListener('submit', function (event) {
								      event.preventDefault();								      	

								      hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
								        if (tokenizeErr) {
								          // Handle error in Hosted Fields tokenization
								          return;
								        }
								        $scope.$apply(function() {
									        vm.fieldsLoaded = false;								       
									    });
								        // Put `payload.nonce` into the `payment-method-nonce` input, and then
								        // submit the form. Alternatively, you could send the nonce to your server
								        // with AJAX.
								        document.querySelector('input[name="payment-method-nonce"]').value = payload.nonce;
								        form.submit();								        					        
								      });
								    }, false);
								  });
								// } else {	

								braintree.paypal.create({
								  client: clientInstance
								}, function (createErr, paypalInstance) {
								  if (createErr) {
								    if (createErr.code === 'PAYPAL_BROWSER_NOT_SUPPORTED') {
								      console.error('This browser is not supported.');
								    } else {
								      console.error('Error!', createErr);
								    }
								  }
								  	$scope.$apply(function() {									    		
									    vm.paypalLoaded = true;								       
									});
								  	var button = document.getElementById('paypal-btn');
									button.addEventListener('click', function () {
									  // Disable the button so that we don't attempt to open multiple popups.
									  // button.setAttribute('disabled', 'disabled');									  
									  // Because PayPal tokenization opens a popup, this must be called
									  // as a result of a user action, such as a button click.
									  	// $scope.$apply(function() {
											// vm.paypalLoaded = false;								       
										// });
									  paypalInstance.tokenize({
									    flow: 'vault',
									    amount: vm.calculatedPrice,
									    currency: 'USD'
									    // Any other tokenization options
									  }, function (tokenizeErr, payload) {								    

									    if (tokenizeErr) {
									      // Handle tokenization errors or premature flow closure
									      switch (tokenizeErr.code) {
									        case 'PAYPAL_POPUP_CLOSED':
									          console.error('Customer closed PayPal popup.');
									          break;
									        case 'PAYPAL_ACCOUNT_TOKENIZATION_FAILED':
									          console.error('PayPal tokenization failed. See details:', tokenizeErr.details);
									          break;
									        case 'PAYPAL_FLOW_FAILED':
									          console.error('Unable to initialize PayPal flow. Are your options correct?', tokenizeErr.details);
									          break;
									        default:
									          console.error('Error!', tokenizeErr);
									      }
									    } else {									      									      
									      var data = {'payment-method-nonce': payload.nonce};
									      PaymentService.paypalPayment(data)			
											.then(function (res) {
											    vm.paypalLoaded = true;										   										
												if (res.status) {
													$state.go('thank');
												}																								
											},
											function (err) {												
											    vm.paypalLoaded = true;
												console.log(err);
											})									     
									    }
									  });
									});

								  
								});
			    		
	    					// }
						}						

					);	    			
    			}, function(err){
    				console.log(err);
    			});
    	};

    	vm.getPaymentData = function () {
    		PaymentService.getPaymentData()
    			.then(function(res) {
    				vm.apData = res;
    				vm.apDataLoaded = true;
    			}, function (err) {
    				console.log(err);
    				$state.go('dash-parent');
    			});
    	}
	
    }
})
(angular);