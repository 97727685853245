(function (angular) {
    'use strict';
    angular
        .module('app.fin')
        .factory('PaymentService', PaymentService);
    PaymentService.$inject = ['$q', '$http'];

    function PaymentService($q, $http) {

        return {            
            getToken: getToken,
            buyForSingle: buyForSingle,
            getCartData: getCartData,
            unsubscribe: unsubscribe,
            getPrice: getPrice,
            paypalPayment: paypalPayment,
            getPaymentData: getPaymentData
        }

        function getToken(){            
            var defer = $q.defer();
            $http.get('/api/payment/token')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }       
        function buyForSingle(data){            
            var defer = $q.defer();
            $http.post('/api/payment/subscribe/single', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        } 
        function getCartData(){            
            var defer = $q.defer();
            $http.get('/api/payment/cart_data')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function unsubscribe(){            
            var defer = $q.defer();
            $http.post('/api/payment/unsubscribe')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function getPrice(){            
            var defer = $q.defer();
            $http.get('/api/payment/get_price')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function paypalPayment(data){            
            var defer = $q.defer();
            $http.post('/api/payment/send', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function getPaymentData(){            
            var defer = $q.defer();
            $http.get('/api/payment/data')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }      
    }
})
(angular);