(function (angular) {
    'use strict';
    angular
        .module('app.profile')
        .factory('ProfileService', ProfileService);
    ProfileService.$inject = ['$q', '$http'];

    function ProfileService($q, $http) {

        return {
            getProfile: getProfile,
            editProfile: editProfile,            
            editChild: editChild,            
            getPurchases: getPurchases,
            getAvaliablePurchases: getAvaliablePurchases,
            sendInvite: sendInvite,
            buyItems: buyItems,
            buyExtraItems: buyExtraItems,
            checkCoupon: checkCoupon,
            changeChildAva: changeChildAva,
            buyExtraLvls: buyExtraLvls,
        }

        function getProfile(){            
            var defer = $q.defer();
            $http.get('/api/profile')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function editProfile(data){          
            var defer = $q.defer();
            $http.post('/api/profile/edit', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function editChild(data){          
            var defer = $q.defer();
            console.log(data);
            $http.post('/api/profile/edit_child', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function getPurchases(){            
            var defer = $q.defer();
            $http.get('/api/profile/purchases')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function getAvaliablePurchases(){            
            var defer = $q.defer();
            $http.get('/api/profile/purchases/avaliable')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function sendInvite(data){            
            var defer = $q.defer();
            $http.post('/api/profile/invite', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function buyItems(data){            
            var defer = $q.defer();
            $http.post('/api/payment', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function buyExtraItems(data){            
            var defer = $q.defer();
            $http.post('/api/payment/extra_level', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function checkCoupon(data){            
            var defer = $q.defer();
            $http.post('/api/payment/coupon', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function changeChildAva(data){            
            var defer = $q.defer();
            $http.post('api/child/change/avatar', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function buyExtraLvls(data){            
            var defer = $q.defer();
            $http.post('api/payment/buy_extra', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }        
        
    }
})
(angular);