(function (angular) {
    'use strict';
    angular
        .module('app.common', ['ui.router', 'slick'])
        .config(routes);
    routes.$inject = ['$stateProvider'];
    
    function routes($stateProvider) {
        
        $stateProvider
            .state('more-tests', {
                url: '/more-tests',
                templateUrl: '/views/Common/tests.html',
                controller: 'CommonCtrl',
                controllerAs: 'commonCtrl'
            })
            .state('more-blog', {
                url: '/more-blog',
                templateUrl: '/views/Common/blog.html',
                controller: 'CommonCtrl',
                controllerAs: 'commonCtrl'
            })
            .state('more-resources', {
                url: '/more-resources',
                templateUrl: '/views/Common/resources.html',
                controller: 'CommonCtrl',
                controllerAs: 'commonCtrl'
            })
            .state('reset', {
                url: '/reset',
                templateUrl: '/views/Common/reset-pass.html',
                controller: 'CommonCtrl',
                controllerAs: 'commonCtrl'
            })
            .state('change-pwd', {
                url: '/change-password',
                templateUrl: '/views/Common/change-pass.html',
                controller: 'CommonCtrl',
                controllerAs: 'commonCtrl'
            })
            .state('privacy', {
                url: '/privacy',
                templateUrl: '/views/Common/privacy.html',
                controller: 'CommonCtrl',
                controllerAs: 'commonCtrl'
            })
            .state('terms', {
                url: '/terms-of-use',
                templateUrl: '/views/Common/terms.html',
                controller: 'CommonCtrl',
                controllerAs: 'commonCtrl'
            })                       
    }
})(angular);
