(function (angular) {
    'use strict';
    angular
        .module('app.landing')
        .controller('LandingCtrl', LandingCtrl);
    LandingCtrl.$inject = ['$q', '$rootScope', '$scope', 'ProfileService', 'AuthService', 'LandingService', 
    'ngDialog', '$timeout', '$state', '$window'];

    function LandingCtrl($q, $rootScope, $scope, ProfileService, AuthService, LandingService, 
        ngDialog, $timeout, $state, $window) {
    	var vm = this;
        vm.mailRegExp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/; 

        if ($rootScope.pathToScroll) {
            $timeout(function(){
                if($rootScope.pathToScroll === 'faq'){

                } else {
                    var lastElemScrollAmount = angular.element( document.querySelector( '#'+$rootScope.pathToScroll ) )[0].offsetTop;
                    var scrollingElement = isWebkit ? document.body : document.documentElement;               
                    angular.element(scrollingElement)[0].scrollTop = lastElemScrollAmount-38;
                }
            },0);
            $rootScope.pathToScroll = '';
        }

        vm.loadProfile = function(){            
            ProfileService.getProfile().then(
                function(res){                    
                    $rootScope.parent = res; 
                    vm.parentLoaded = true;                   
                }, function(err){                    
                    
                });             
        };
        vm.loadProfile();

    	vm.signOnNews = function(email){
    		var data;    		
    		if (vm.mailRegExp.test(email)) {
    			data = {email: email};
    		} else {
    			return;
    		}        	
            AuthService.signOnNews(data).then(
             function(res){                 
                 alert('You have been subscribed');                 
             }, function(err){
                console.log(err);
             });
        }
        var chooseModal = null;
        function addOpacity(blockName){
            $timeout(function(){                
                var popup = angular.element(document.getElementsByClassName(blockName)).removeClass("invisible");                
            }, 500);
        }
        vm.openChoosePopup = function(){
            // console.log('Choose');            
            chooseModal = ngDialog.open({ 
                template: '/views/Auth/popup-choose-sign-in.html', 
                className: 'popup-choose-sign-in invisible ngdialog-theme-default',               
                scope: $scope,               
                controller: 'AuthCtrl',
                controllerAs: 'authCtrl',
                name: 'chooseModal'                       
            });
            addOpacity('popup-choose-sign-in');
        };
        var parentModal;
        vm.openParentPopup = function(flag){
            chooseModal.close();
            parentModal = ngDialog.open({ 
                template: '/views/Auth/popup-parent-sign.html', 
                className: 'popup-parent-sign invisible ngdialog-theme-default',
                data: {
                    flag: flag
                },
                scope: $scope,
                controller: 'AuthCtrl',
                controllerAs: 'authCtrl'                 
            });
            addOpacity('popup-parent-sign');
        };
                     
        vm.openChildPopup = function(){
            chooseModal.close();
            ngDialog.open({ 
                template: '/views/Auth/popup-child-sign.html', 
                className: 'popup-child-sign invisible ngdialog-theme-default',
                scope: $scope,
                controller: 'AuthCtrl',
                controllerAs: 'authCtrl'                     
            });
            addOpacity('popup-child-sign');
        };

        vm.closePopup = function(){            
            ngDialog.closeAll();
        };

        vm.loadUserPurchases = function(){    		
    		ProfileService.getPurchases().then(
                function(res){
                    vm.purchasedTests = res;
                }, function(err){
                    console.log(err);                    
                });
    	};
    	vm.loadAvaliablePurchases = function(){    		
    		ProfileService.getAvaliablePurchases().then(
                function(res){
                    vm.avaliableTests=res;                    
                }, function(err){
                    console.log(err);                    
                });
    	};
    	var testDialog;
    	vm.openTestPreview = function(test){
    		testDialog = ngDialog.open({ 
				template: '/views/Profile/popup-test-preview.html', 
				className: 'popup-test-preview ngdialog-theme-default',
				data: {
					test: test
				},
                scope: $scope,	
    		});
		};

		vm.buySeasonTicket = function(item){
    		console.log(item);
    	};
    	
        vm.bpFirstSlider = [
            {
              breakpoint: 1570,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 970,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
              {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
             }
            
          ];

        vm.bpSecondSlider = [
            {
              breakpoint: 1570,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 970,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ];

        var w = angular.element($window);
        $scope.$watch(function () {
                return $window.innerWidth;
            },
            function (value) {
                vm.screenWidth = value;
                if (value <= 695) {
                    $timeout(function () {
                        vm.hideMobileElem = true;
                    }, 1000)
                } else {
                    vm.hideMobileElem = false;                    
                }
                
            }, true);
        w.bind('resize', function () {
            $scope.$apply();
        });

    }
})
(angular);