(function (angular) {
    'use strict';
    angular
        .module('app.dash')
        .controller('DashCtrl', DashCtrl);
    DashCtrl.$inject = ['$q', '$rootScope', '$scope', 'ProfileService', 'AuthService', 'ngDialog', 'ChildService', '$state', '$timeout'];

    function DashCtrl($q, $rootScope, $scope, ProfileService, AuthService, ngDialog, ChildService, $state, $timeout) {
    	var vm = this;
                
    	vm.loadProfile = function(){    		
    		ProfileService.getProfile().then(
                function(res){                    
                    if (res.parent_id) {
                        $state.go('dash-child');
                    }
                    $rootScope.parent = res;
                    $rootScope.parentLoaded = true; 
                    $rootScope.fromParent = 'false';                  
                }, function(err){
                    console.log(err);
                    vm.emailRegistred = true;
                    $state.go('landing');
                });      		
    	};

        if ($rootScope.fromParent === 'true') { 
            $state.go('dash-child');
        } else {            
           vm.loadProfile();
        }        
    	
    	vm.loadChildReports = function(childId){ 
    		ChildService.loadChildReports(childId).then(
                function(res){
                    vm.childReports = res;
                    vm.reportsLoaded=true;
                }, function(err){
                    console.log(err);
                    
                });      		
    	};
    	
    	vm.editChild = function(avatar, firstname, lastname, username, grade, pass, id){

            if (avatar === undefined) {
                avatar = '/images/player1.png';
            }
            var data = {
                id: id
            };
            if (firstname !== undefined) {
                data.firstname = firstname;
            }
            if (lastname !== undefined) {
                data.lastname = lastname;
            }
            if (username !== undefined) {
                data.username = username;
            }
            if (grade !== undefined) {
                data.grade = grade;
            }
            if (pass !== undefined && pass !== '') {
                data.pass = pass;
            }
            if (avatar !== undefined) {
                data.avatar = avatar;
            }           
            if(!data){
                return;
            } 
            ProfileService.editChild(data).then(
                function(res){
                    vm.currChild = res;
                    $rootScope.parent[vm.currChildIndex] = res;
                    vm.childEditedSuc = true;
                    $timeout(function (argument) {
                        vm.childEditedSuc = false;
                    }, 3000);
                }, function(err){
                    console.log(err);
                    vm.loginRegistred = true;
                });             
        };

    	var testDialog;
    	vm.openTestPreview = function(test){
    		testDialog = ngDialog.open({ 
				template: '/views/Profile/popup-test-preview.html', 
				className: 'popup-test-preview ngdialog-theme-default',
				data: {
					test: test
				},
                scope: $scope,  
				cache: false		
    		});
		};

		vm.buyItems = function(id){
            var data = {child_id: id};
    		var ids = [];
    		var items = angular.element(document.getElementsByClassName("aval-test-item"));    		
    		for (var i = 0; i < items.length; i++) {    		
    			if (items[i].checked) {
    				ids.push(parseInt(items[i].value, 10));
    			}
    		}
            if (ids.length > 0) {
                data.tests = ids;
            } else {
                return;
            } 
            ProfileService.buyExtraLvls(data).then(
                function(res){
                    $state.go('customize');                   
                }, function(err){
                    console.log(err);
                    vm.loginRegistred = true;
                }); 
		};
		vm.addRewardEvent = function(childId, title, amount, subj, image){			
			var data = {
				title: title,
				questionsToReward: amount,
                image: image
			};

			var subjIds = [];
			for (var i = 0; i < subj.length; i++) {
				subjIds.push(subj[i].id);
			}
			data.subjectsIds = subjIds;	
			ChildService.addRewardEvent(childId, data).then(
             function(res){
                 vm.currChild = res;
                 $rootScope.parent[vm.currChildIndex] = res; 
                 vm.rewTitle = '';
                 vm.rewAmount = '';
                 vm.rewSubj = '';               
             }, function(err){
                console.log(err);
             });   		
    		
		};

		vm.onDropComplete = function (index, obj, evt) {
            var otherObj = vm.currChild.event_rewards[index];
            var otherIndex = vm.currChild.event_rewards.indexOf(obj);
            vm.currChild.event_rewards[index] = obj;
            vm.currChild.event_rewards[otherIndex] = otherObj;

            var ids = []; 		
    		for (var i = 0; i < vm.currChild.event_rewards.length; i++) {
				ids.push(parseInt(vm.currChild.event_rewards[i].id, 10));    			
    		}    		
    		ChildService.updateRewards(vm.currChild.id, ids)
				.then(function(res){
						console.log(res);						
					}, function(err){
						console.log(err);
					});           
        };

    }
})
(angular);