(function (angular) {
    'use strict';
    angular
        .module('app.fin', ['ui.router', 'angular-ladda'])
        .config(routes);
    routes.$inject = ['$stateProvider', 'laddaProvider'];
    
    function routes($stateProvider, laddaProvider) {        
        $stateProvider
            .state('finances', {
                url: '/finances',
                templateUrl: '/views/Finances/finances.html',
                controller: 'FinCtrl',
                controllerAs: 'finCtrl'
            })
            .state('thank', {
                url: '/thank-you',
                templateUrl: '/views/Finances/thank.html',
                controller: 'FinCtrl',
                controllerAs: 'finCtrl'
            });
            laddaProvider.setOption({ 
              style: 'expand-right',
              spinnerSize: 28,
              spinnerColor: '#ffffff'
            });

    }
    
})(angular);
