(function (angular) {
    'use strict';
    angular
        .module('app.dash')
        .controller('DashChildCtrl', DashChildCtrl);
    DashChildCtrl.$inject = ['$q', '$rootScope', '$scope', 'ProfileService', '$state', 'ChildService', '$window'];

    function DashChildCtrl($q, $rootScope, $scope, ProfileService, $state, ChildService, $window) {

    	var vm = this;       

    	vm.loadProfile = function(){
    		ProfileService.getProfile().then(
                function(res){                    
                    if (res.children) {
                        $state.go('dash-parent');
                    }                                    
                    $rootScope.child = res;
                    if (!$rootScope.currGrade) {
                        $rootScope.currGrade = res.SavedGrades[0];
                        $rootScope.currGradeIndex = 0;
                        if (!$rootScope.currTest) {
                            $rootScope.currTest = $rootScope.currGrade.SavedTests[0];
                            $rootScope.currTestIndex = 0;
                        }
                    }
                    $rootScope.fromParent = 'true';
                    $rootScope.childLoaded = true;                                                                          
                }, function(err){
                    console.log(err);
                    $state.go('landing');
                });      		
    	};

        if ($rootScope.fromParent === 'false') {                       
            $state.go('dash-parent');
        } else {                   
            if (!$rootScope.child) {                
                vm.loadProfile();
            }
        }

        var form = document.querySelector('#game-form');
        vm.finishLvl = function(topic, lvl){        	
            form.querySelector('input[name="Grade"]').value = $rootScope.currGrade.grade_title;
            form.querySelector('input[name="Test"]').value = $rootScope.currTest.TestTitle;
            form.querySelector('input[name="Topic"]').value = topic;
            form.querySelector('input[name="Level"]').value = lvl;
            form.querySelector('input[name="is_finish"]').value = 1;
            form.submit();            
        };
        vm.playLvl = function(topic, lvl){            
            form.querySelector('input[name="Grade"]').value = $rootScope.currGrade.grade_title;
            form.querySelector('input[name="Test"]').value = $rootScope.currTest.TestTitle;
            form.querySelector('input[name="Topic"]').value = topic;
            form.querySelector('input[name="Level"]').value = lvl;
            form.querySelector('input[name="is_finish"]').value = 0;
        	form.submit();                     
        };
        
        vm.changeAvatar = function(avatar){
        	var data = {'avatar_path':avatar};
        	ProfileService.changeChildAva(data).then(
                function(res){
        			$rootScope.child.Avatar = avatar;             
                }, function(err){
                    console.log(err);
                    $state.go('landing');
                });
        	
        };

        vm.chooseTest = function (currTest, index) {
            vm.showGradeDropDown = false;
            $rootScope.currTest = currTest;
            $rootScope.currTestIndex = index;
        };
        vm.changeGrade = function (currGrade, index) {
            $rootScope.currGrade = currGrade;
            $rootScope.currGradeIndex = index;
        };
    	
    }
})
(angular);