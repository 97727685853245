(function (angular) {
    'use strict';
    angular
        .module('app.auth', ['ui.router', 'ngDialog'])
        .config(routes);
    routes.$inject = ['$stateProvider'];
    
    function routes($stateProvider) {
        
        $stateProvider
            .state('auth', {
                url: '/auth',
                templateUrl: '/views/Auth/auth.html',
                controller: 'AuthCtrl',
                controllerAs: 'authCtrl'
            })
            .state('reg', {
                url: '/registration',
                templateUrl: '/views/Auth/registration.html',
                controller: 'RegCtrl',
                controllerAs: 'regCtrl'
            }) 
            .state('add-child', {
                url: '/add-student',
                templateUrl: '/views/Dashboard/add-child.html',
                controller: 'RegCtrl',
                controllerAs: 'regCtrl'
            });            
    }
})(angular);
