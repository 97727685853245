(function (angular) {
    'use strict';
    angular
        .module('app.common')
        .controller('CommonCtrl', CommonCtrl);
    CommonCtrl.$inject = ['$q', '$rootScope', '$scope', 'ProfileService', 'AuthService', 'LandingService', 
    'ngDialog', '$timeout', '$state'];

    function CommonCtrl($q, $rootScope, $scope, ProfileService, AuthService, LandingService, 
    	ngDialog, $timeout, $state) {
	    	var vm = this;
            var mailRegExp = /^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/;

            vm.restorePassword = function (email) {
                if (!mailRegExp.test(email)) {
                    vm.err = 0;
                    return;
                }
                AuthService.restorePassword({'email': email})
                .then(function(res){
                        vm.resetSuccess = true;
                        $timeout(function () {
                           $state.go('landing');
                        }, 2750);                         
                        vm.email = '';                       
                    }, function(err){
                        console.log(err);
                        vm.err = err.error_id;
                    });
                
            };
            vm.saveNewPassword = function (pass, confirmPass) {
                if (pass !== confirmPass) {
                    vm.errCode = 1;
                    return;
                }            
                var upper = false;
                var lower = false;
                for(var i=0; i<pass.length; i++){
                    var passChar = pass.charCodeAt(i);                
                    if (passChar >= 65 && passChar <= 90) {
                        upper = true;
                    } else if(passChar >= 97 && passChar <= 122){
                        lower = true;
                    }
                }
                if (!upper || !lower) {
                    vm.errCode = 2;
                    return;
                }               
                var token = window.location.href.split('password_token=')[1];
                var data = {'password': pass, 'password_confirmation': confirmPass, 'password_token': token};                                          
                AuthService.saveNewPassword(data)
                .then(function(res){
                        vm.resetSuccess = true;                        
                        vm.pass = '';                       
                        vm.confirmPass = ''; 
                        $timeout(function () {
                           $state.go('landing');
                        }, 2750);                      
                    }, function(err){
                        console.log(err);
                        vm.err = err.error_id;
                    });
                
            };

   }
})
(angular);