(function (angular) {
    'use strict';
    angular
        .module('app.dash')
        .factory('ChildService', ChildService);
    ChildService.$inject = ['$q', '$http'];

    function ChildService($q, $http) {

        return {            
            loadChildReports: loadChildReports,
            addRewardEvent: addRewardEvent,            
            updateRewards: updateRewards 
        }

        function loadChildReports(childId){            
            var defer = $q.defer();
            $http.get('/api/child/'+childId+'/reports')
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function addRewardEvent(childId, data){            
            var defer = $q.defer();
            $http.post('/api/child/'+childId+'/add_reward_event', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
        function updateRewards(childId, data){            
            var defer = $q.defer();
            $http.post('/api/child/'+childId+'/update/reports', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
       
        
    }
})
(angular);