(function (angular) {
    'use strict';
    angular
        .module('app.landing')
        .factory('LandingService', LandingService);
    LandingService.$inject = ['$q', '$http'];

    function LandingService($q, $http) {

        return {            
            contactForm: contactForm                        
        }

        function contactForm(data){            
            var defer = $q.defer();
            $http.post('/api/contact_form', data)
                .success(function (response){
                    defer.resolve(response);
                })
                .error(function (error){
                    defer.reject(error);
                });
            return defer.promise;            
        }
         
    }
})
(angular);